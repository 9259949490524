var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[(_vm.filtersVisible)?_c('v-col',{attrs:{"cols":_vm.filterColumnCount}},[_c('base-card')],1):_vm._e(),_c('v-col',{attrs:{"cols":_vm.gridColumnCount}},[_c('base-card',[_c('v-card-title',[_c('div',{staticClass:"d-flex justify-space-between flex"},[_c('h5',{staticClass:"ma-5 mb-0 d-flex flex"},[_vm._v("Brands")]),_c('v-text-field',{staticClass:"col-5",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},on:{"input":_vm.triggerQuicksearch},model:{value:(_vm.quicksearch),callback:function ($$v) {_vm.quicksearch=$$v},expression:"quicksearch"}}),_c('v-btn',{staticClass:"ma-2",attrs:{"dark":"","color":"danger","to":"/mng/adpoints/brands/create"}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add Brand ")],1)],1)]),_c('v-data-table',{staticClass:"elevation-1 table-one",attrs:{"headers":_vm.headers,"options":_vm.pagingOptions,"server-items-length":_vm.totalItemCount,"items":_vm.gridData,"item-key":"name","show-select":false,"multi-sort":""},on:{"update:options":function($event){_vm.pagingOptions=$event}},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"26","dark":""}},[_c('img',{attrs:{"src":"/img/database.c82dbe43.svg","alt":""}})]),_c('p',{staticClass:"ma-0 font-weight-medium"},[_vm._v(" "+_vm._s(item.title)+" ")])],1)]}},{key:"item.aud_create_date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"ma-0 font-weight-medium"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.aud_create_date))+" ")])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","dark":"","icon":"","to":'/mng/adpoints/brands/edit/' + item.code}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-box-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"danger","dark":"","icon":""},on:{"click":function($event){return _vm.deleteShowConfirmDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',{},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Delete")])]),_c('v-card-text',[_c('v-container',[_vm._v(" Are you sure you want to delete this record? ")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary darken-1","text":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary darken-1","text":""},on:{"click":function($event){return _vm.deleteItem()}}},[_vm._v("Delete")])],1)],1)],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":4000,"top":"","tile":"","color":_vm.snackbarColor + ' -accent-2'},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }